import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from 'react-router-dom';
import RestroSettings from '../RestroSettings/components/RestroSettings';
import DynamicModal from '../Form/GenericFields/DynamicModal';
import { MENU_ITEM_SIDEBAR } from '../Utils/enum';
import { connect } from 'react-redux';

function mapStateToProps(state) {

    const { loginUserData, isLoading, errorMessage } = state.loginModuleReducer.GetLoginReducer;
    const { values: loginValues } = state.form.loginForm || {};

    return { loginUserData, isLoading, errorMessage, loginValues };
}

const mapDispatchToProps = {
    
};
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            userRole: JSON.parse(localStorage.getItem("user"))?.role
        };
    }

    handleClose = () => {
        this.setState({ showModal: false });
    };

    handleShow = () => {
        this.setState({ showModal: true });
    };
    componentDidUpdate = (prevProps) => {
        const { loginUserData, history } = this.props;
        if (prevProps.loginUserData !== loginUserData ) {
            if (loginUserData.token) {
                this.setState({  userRole:loginUserData.role});
            }
           
        }
    }
    

    render() {
        
        const { children } = this.props;
        const { showModal, userRole } = this.state;

        return (
            <div className="container-fluid">
                <div className="row flex-nowrap" style={{ overflow: "hidden" ,height : "90vh"}}>
                    <div className='col-auto' style={{ paddingLeft: 0, paddingRight: 0 , paddingTop: "10px" }}>
                        <div>
                            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                                {this.props.loginUserData && userRole && MENU_ITEM_SIDEBAR.filter(e=> e.role === userRole).map((item, index) =>
                                    item.showModal ? (
                                        <li className="nav-item" key={index} onClick={this.handleShow}>
                                            <Nav.Link to={"#"}>
                                                <div className="icon">{item.icon}</div>
                                            </Nav.Link>
                                        </li>
                                    ) : (
                                        <li className="nav-item" key={index}>
                                            <LinkContainer to={item.path}>
                                                <Nav.Link>
                                                    <div className="icon">{item.icon}</div>
                                                </Nav.Link>
                                            </LinkContainer>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className='col' style={{paddingTop:"30px", overflowY: "scroll"}}>{children}</div>
                </div>
                <DynamicModal
                    showModal={showModal}
                    handleClose={this.handleClose}
                    title="Dynamic Modal"
                    body={<RestroSettings />}
                    showButtons={false} // or false, depending on your requirement
                />
            </div>
        );
    }
}

export default(connect(
    mapStateToProps, mapDispatchToProps
)(Sidebar));
