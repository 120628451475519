import React, { Component } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { getPDFBase64Request } from '../../redux/actions/invoiceData.action';
import DynamicModal from '../../../Form/GenericFields/DynamicModal';
import IframePreview from '../CreateInvoice/IframePreview';
import { connect } from 'react-redux';

function mapStateToProps(state) {

  const {  base64InvoicePDF } = state.masterDataModuleReducer.masterDataReducer;

  return { base64InvoicePDF };
}

const mapDispatchToProps = {
  getPDFBase64Request
};


class InvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      sortBy: '',
      sortOrder: 'asc',
      showModal: false,
      logo:''
    };
  }

  handleClose = ()=>{
    this.setState({showModal: false})
  }

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleSort = (field) => {
    const { sortBy, sortOrder } = this.state;
    if (sortBy === field) {
      this.setState({ sortOrder: sortOrder === 'asc' ? 'desc' : 'asc' });
    } else {
      this.setState({ sortBy: field, sortOrder: 'asc' });
    }
  };

  showInvoice = (invoiceId) =>{
    const { invoiceData } = this.props;
    this.props.getPDFBase64Request(invoiceData.filter(e => e.invoiceId === invoiceId))
    //this.setState({showModal: true})
  }
  componentDidMount = () => {
    this.getBase64FromUrl('asset/logo/logo.jpg')
        .then(base64 => {
            this.setState({ logo: base64 });
        })
        .catch(error => console.error(error));

}


getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

componentDidUpdate = (prevProps) => {
  if (prevProps.base64InvoicePDF !== this.props.base64InvoicePDF) {
        this.setState({ showModal: true })
}
}

  render() {
    const { invoiceData } = this.props;
    const { searchTerm, sortBy, sortOrder , logo , showModal} = this.state;

    // Filter and sort invoiceData based on search term, sortBy, and sortOrder
    const filteredInvoices = Array.isArray(invoiceData)
      ? invoiceData.filter((invoice) =>
          invoice.invoiceId.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

     // Apply sorting if sortBy field is specified
     if (sortBy) {
        filteredInvoices.sort((a, b) => {
          const valueA = a[sortBy];
          const valueB = b[sortBy];
          if (sortOrder === 'asc') {
            return valueA > valueB ? 1 : -1;
          } else {
            return valueA < valueB ? 1 : -1;
          }
        });
      }

    return (
      <div>
        <Form className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search by Invoice ID"
            value={searchTerm}
            onChange={this.handleSearchChange}
          />
        </Form>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th onClick={() => this.handleSort('invoiceId')}>Invoice ID</th>
              <th onClick={() => this.handleSort('buyerFirmName')}>Buyer Name</th>
              <th onClick={() => this.handleSort('buyerFirmState')}>State</th>
              <th onClick={() => this.handleSort('buyerFirmAddress')}>Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvoices?.map((invoice, index) => (
              <tr key={index}>
                <td>{invoice.invoiceId}</td>
                <td>{invoice.buyerFirmName}</td>
                <td>{invoice.buyerFirmState}</td>
                <td>{invoice.buyerFirmAddress}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => this.props.getPDFBase64Request({...invoice, logo})}>
                    Show Invoice
                  </Button>{' '}
                  {/* <Button variant="primary" size="sm" onClick={() => this.props.onEditInvoice(invoice)}>
                    Edit Invoice
                  </Button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <DynamicModal
                    showModal={showModal}
                    handleClose={this.handleClose}
                    title="Dynamic Modal"
                    size='lg'
                    body={
                    <div >
                         <IframePreview base64Content={this.props.base64InvoicePDF} />
                    </div>
                    }
                    showButtons={false} // or false, depending on your requirement
                />
      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(InvoiceTable);
