import { call, put, takeLatest } from "redux-saga/effects";
import { CREATE_FIRM_DATA_REQUEST, DELETE_FIRM_DATA_REQUEST, FILE_INSERT_FIRM_DATA_REQUEST, GET_FIRM_DATA_REQUEST, UPDATE_FIRM_DATA_REQUEST, createFirmDataRequestFailure, createFirmDataRequestSuccess, deleteFirmDataRequestFailure, deleteFirmDataRequestSuccess, fileInsertFirmDataRequest, fileInsertFirmDataRequestFailure, fileInsertFirmDataRequestSuccess, getFirmDataRequestFailure, getFirmDataRequestSuccess, printRequestFailure, printRequestSuccess, updateFirmDataRequestFailure, updateFirmDataRequestSuccess } from "../actions/firmData.action";
import { getServiceDataSchema } from "../schemas/getServiceDataSchema.schema";
import { sagaHandle } from "../../../Utils/sagaDataHandling";
import { Mutation, Query } from "../../../../api/graphql.config";
import { CREATE_SERVICE_DATA_REQUEST, DELETE_SERVICE_DATA_REQUEST, GET_SERVICE_DATA_REQUEST, UPDATE_SERVICE_DATA_REQUEST, createServiceDataRequestFailure, createServiceDataRequestSuccess, deleteServiceDataRequestFailure, deleteServiceDataRequestSuccess, getServiceDataRequestFailure, getServiceDataRequestSuccess, updateServiceDataRequestFailure, updateServiceDataRequestSuccess } from "../actions/serviceData.action";
import { getFirmDataSchema } from "../schemas/getFirmDataSchema.schema copy";
import { getInvoiceDataSchema } from "../schemas/getInvoiceDataSchema";
import { CREATE_INVOICE_REQUEST, GET_INVOICE_DATA_REQUEST, GET_PDF_BASE64_REQUEST, createInvoiceRequestFailure, createInvoiceRequestSuccess, getInvoiceDataRequestFailure, getInvoiceDataRequestSuccess, getPDFBase64RequestFailure, getPDFBase64RequestSuccess } from "../actions/invoiceData.action";
import { createInvoiceSchema } from "../schemas/createInvoiceSchema.schema";
import { getPDFBase64Schema } from "../schemas/getPDFBase64Schema.schema";
import { createFirmDataSchema } from "../schemas/createFirmDataSchema.schema";
import { createServiceDataSchema } from "../schemas/createServiceDataSchema.schema";
import { updateServiceDataSchema } from "../schemas/updateServiceDataSchema.schema";
import { deleteServiceDataSchema } from "../schemas/deleteServiceDataSchema.schema";
import { updateFirmDataSchema } from "../schemas/updateFirmDataSchema.schema";
import { deleteFirmDataSchema } from "../schemas/deleteFirmDataSchema.schema";
import { uploadFirmDataSchema } from "../schemas/uploadFirmDataSchema.schema";

export function* getFirmDataRequestSaga() {
    try {
        yield call(
            sagaHandle,
            Query,
            getFirmDataSchema(),
            getFirmDataRequestSuccess,
            getFirmDataRequestFailure
        )
    } catch (error) {
        put(getFirmDataRequestFailure(error));
    }
}

export function* getServiceDataRequestSaga() {
    try {
        yield call(
            sagaHandle,
            Query,
            getServiceDataSchema(),
            getServiceDataRequestSuccess,
            getServiceDataRequestFailure
        )
    } catch (error) {
        put(getServiceDataRequestFailure(error));
    }
}

export function* getInvoiceDataRequestSaga() {
    try {
        yield call(
            sagaHandle,
            Query,
            getInvoiceDataSchema(),
            getInvoiceDataRequestSuccess,
            getInvoiceDataRequestFailure
        )
    } catch (error) {
        put(getInvoiceDataRequestFailure(error));
    }
}

export function* createInvoiceRequestSaga({payload}) {
    ;
    try {
        yield call(
            sagaHandle,
            Mutation,
            createInvoiceSchema(payload),
            createInvoiceRequestSuccess,
            createInvoiceRequestFailure
        )
    } catch (error) {
        put(createInvoiceRequestFailure(error));
    }
}

export function* getPDFBase64RequestSaga({payload}) {
    
    try {
        yield call(
            sagaHandle,
            Mutation,
            getPDFBase64Schema(payload),
            getPDFBase64RequestSuccess,
            getPDFBase64RequestFailure
        )
    } catch (error) {
        put(getPDFBase64RequestFailure(error));
    }
}

export function* createFirmDataRequestSaga({payload}) {
    
    try {
        yield call(
            sagaHandle,
            Mutation,
            createFirmDataSchema(payload),
            createFirmDataRequestSuccess,
            createFirmDataRequestFailure
        )
    } catch (error) {
        put(createFirmDataRequestFailure(error));
    }
}

export function* createServiceDataRequestSaga({payload}) {
    
    try {
        yield call(
            sagaHandle,
            Mutation,
            createServiceDataSchema(payload),
            createServiceDataRequestSuccess,
            createServiceDataRequestFailure
        )
    } catch (error) {
        put(createServiceDataRequestFailure(error));
    }
}

export function* updateFirmDataRequestSaga({payload}) {
    
    try {
        yield call(
            sagaHandle,
            Mutation,
            updateFirmDataSchema(payload),
            updateFirmDataRequestSuccess,
            updateFirmDataRequestFailure
        )
    } catch (error) {
        put(updateFirmDataRequestFailure(error));
    }
}

export function* deleteFirmDataRequestSaga({payload}) {
    
    try {
        yield call(
            sagaHandle,
            Mutation,
            deleteFirmDataSchema(payload),
            deleteFirmDataRequestSuccess,
            deleteFirmDataRequestFailure
        )
    } catch (error) {
        put(deleteFirmDataRequestFailure(error));
    }
}

export function* updateServiceDataRequestSaga({payload}) {
    
    try {
        yield call(
            sagaHandle,
            Mutation,
            updateServiceDataSchema(payload),
            updateServiceDataRequestSuccess,
            updateServiceDataRequestFailure
        )
    } catch (error) {
        put(updateServiceDataRequestFailure(error));
    }
}

export function* deleteServiceDataRequestSaga({payload}) {
    try {
        yield call(
            sagaHandle,
            Mutation,
            deleteServiceDataSchema(payload),
            deleteServiceDataRequestSuccess,
            deleteServiceDataRequestFailure
        )
    } catch (error) {
        put(deleteServiceDataRequestFailure(error));
    }
}

export function* uploadFirmDataRequestSaga({payload}) {
    console.log(payload);
    try {
        yield call(
            sagaHandle,
            Mutation,
            uploadFirmDataSchema(payload),
            fileInsertFirmDataRequestSuccess,
            fileInsertFirmDataRequestFailure
        )
    } catch (error) {
        put(fileInsertFirmDataRequestFailure(error));
    }
}



export default function* userFirmDataDataSagas() {
    yield takeLatest(GET_FIRM_DATA_REQUEST, getFirmDataRequestSaga)
    yield takeLatest(GET_SERVICE_DATA_REQUEST, getServiceDataRequestSaga)
    yield takeLatest(GET_INVOICE_DATA_REQUEST, getInvoiceDataRequestSaga)
    yield takeLatest(CREATE_INVOICE_REQUEST,createInvoiceRequestSaga )
    yield takeLatest(GET_PDF_BASE64_REQUEST,getPDFBase64RequestSaga )
    yield takeLatest(CREATE_FIRM_DATA_REQUEST,createFirmDataRequestSaga )
    yield takeLatest(CREATE_SERVICE_DATA_REQUEST,createServiceDataRequestSaga )
    yield takeLatest(UPDATE_SERVICE_DATA_REQUEST,updateServiceDataRequestSaga )    
    yield takeLatest(DELETE_SERVICE_DATA_REQUEST,deleteServiceDataRequestSaga )
    yield takeLatest(UPDATE_FIRM_DATA_REQUEST,updateFirmDataRequestSaga )
    yield takeLatest(DELETE_FIRM_DATA_REQUEST,deleteFirmDataRequestSaga )
    yield takeLatest(FILE_INSERT_FIRM_DATA_REQUEST,uploadFirmDataRequestSaga )
}