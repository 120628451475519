import { connect } from 'react-redux';
import { setAddedServiceDataRequest, updateAddedServiceDataRequest } from '../../redux/actions/serviceData.action';
import React, { Component } from 'react';
import { Button, Table } from 'react-bootstrap';
import { reset } from 'redux-form';
import AddServiceForm from './AddServiceForm';
import { createInvoiceRequest } from '../../redux/actions/invoiceData.action';

function mapStateToProps(state) {
    const { addedServiceData, serviceData, addedFirmData, addedAdminFirmData, addedBuyerFirmData, createInvoice } = state.masterDataModuleReducer.masterDataReducer;
    const { State, City, selectedServiceData, season, year } = state.form.addServiceForm?.values || ''
    return { addedServiceData, State, City, selectedServiceData, season, serviceData, year, addedFirmData, addedAdminFirmData, addedBuyerFirmData, createInvoice };
}

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(reset('addServiceForm')),
    setAddedServiceDataRequest: (newService) => dispatch(setAddedServiceDataRequest(newService)),
    updateAddedServiceDataRequest: (updateService) => dispatch(updateAddedServiceDataRequest(updateService)),
    createInvoiceRequest: (updateService) => dispatch(createInvoiceRequest(updateService))
});

class ServiceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedService: '',
            hsn: '',
            quantity: '',
            rate: '',
            igst: 0,
            cgst: 0,
            sgst: 0,
            totalGross: 0,
            totalTax: 0,
            netAmount: 0,
            services: [],
            selectedCountryCode: 'IN',
            selectedStateCode: '',
            selectedCityCode: "",
            errorMessageShow: ''
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleServiceChange = (event) => {
        const selectedService = event.value;
        const service = this.props.serviceData.find((item) => item.product_name === selectedService);

        if (service) {
            this.setState({
                selectedService,
                hsn: service.hsn_sac,
                rate: service.rate_per_count,
                gstin: service.gstin_percentage
            });
        }
    };

    handleAddService = () => {
        const { setAddedServiceDataRequest, updateAddedServiceDataRequest, State, City, selectedServiceData, season, year, addedServiceData, addedFirmData, addedAdminFirmData, addedBuyerFirmData } = this.props;
        const { hsn, quantity, rate, gstin } = this.state;
        const productName = selectedServiceData.value + " distt " + City.value + " Season " + season.value + " " + year.value;
        const serviceExist = addedServiceData?.find(e => e.product_name === productName);
        const buyerState = addedBuyerFirmData.state;
        const adminState = addedAdminFirmData.state;
        const igst = buyerState !== adminState ? (gstin) : 0;
        const cgst = buyerState === adminState ? ((gstin * (1 / 2))) : 0;
        const sgst = buyerState === adminState ? ((gstin * (1 / 2))) : 0;
        const netAmount = (serviceExist ? parseFloat(serviceExist?.netAmount) : 0) + parseFloat(rate) * parseFloat(quantity);
        const totalTax = netAmount * ((igst + cgst + sgst) / 100);
        const totalGross = netAmount + totalTax;

        const newService = {
            product_name: productName,
            hsn,
            quantity: (serviceExist ? parseFloat(serviceExist?.quantity) : 0) + parseFloat(quantity),
            rate: parseFloat(rate),
            igst,
            cgst,
            sgst,
            netAmount,
            totalTax,
            totalGross
        };
        if (serviceExist) {
            updateAddedServiceDataRequest({ id: productName, updatedData: newService })
        } else {
            setAddedServiceDataRequest(newService)
        }

        this.setState((prevState) => ({
            //services: [...prevState.services, newService],
            selectedService: '',
            hsn: '',
            quantity: '',
            rate: ''
        }));
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.addedServiceData !== this.props.addedServiceData) {
            this.props.addServiceToState(this.props.addedServiceData);
            this.props.resetForm();
        }
        if (prevProps.createInvoice !== this.props.createInvoice) {
           this.props.createPDF()
            this.props.nextStep();
        }
    }

    saveInvoice = () => {
        this.props.createInvoiceRequest(this.props.invoiceData);
    }

    render() {
        const { selectedService, hsn, quantity, rate, services, errorMessageShow } = this.state;
        const { addedServiceData, handleSubmit, serviceData, invoiceData } = this.props;


        return (
            <div className="container mt-4">
                {invoiceData.productDetails.length > 0 && <><h4 className="mt-4">{errorMessageShow && errorMessageShow}</h4>
                    <h2 className="mt-4">Service Table</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>HSN/SAC</th>
                                <th>Quantity</th>
                                <th>Rate (Rs.)</th>
                                <th>IGST</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>Total Gross (Rs.)</th>
                                <th>Total Tax (Rs.)</th>
                                <th>Net Amount (Rs.)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceData.productDetails?.map((service, index) => (
                                <tr key={index}>
                                    <td>{service.product_name}</td>
                                    <td>{service.hsn}</td>
                                    <td>{service.quantity}</td>
                                    <td>{service.rate}</td>
                                    <td>{service.igst + "%"}</td>
                                    <td>{service.cgst + "%"}</td>
                                    <td>{service.sgst + "%"}</td>
                                    <td>{service.totalGross}</td>
                                    <td>{service.totalTax}</td>
                                    <td>{service.netAmount}</td>
                                    <td>
                                        <Button className='btn btn-danger rounded-pill px-3' onClick={() => this.setState({ errorMessageShow: this.props.onDeleteProduct(service.product_name) })}>
                                            Delete Item
                                        </Button>{' '}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
                }
                <AddServiceForm
                    {...this.props}
                    handleInputChange={this.handleInputChange}
                    onSubmit={this.handleAddService}
                    handleServiceChange={this.handleServiceChange}
                    selectedService={selectedService}
                    serviceData={serviceData}
                    hsn={hsn}
                    quantity={quantity}
                    rate={rate}
                    services={services}
                    saveInvoice={this.saveInvoice}
                    currentStep={this.props.currentStep}
                    prevStep={this.props.prevStep}
                    nextStep={this.props.nextStep}
                />

            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ServiceForm);
