import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./less/main.css"
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import Header from './modules/Header/components/Header';
import Footer from './modules/Footer/components/Footer';
import Home from './modules/Home/components/Home';
import AboutUs from './modules/AboutUs/components/AboutUs';
import UserProfile from './modules/UserProfile/components/UserProfile';
import Login from './modules/Authentication/Login/components/Login';
import Registration from './modules/Authentication/Registration/components/Registration';
import UserAddress from './modules/UserProfile/components/UserAddress';
import SideBar from './modules/SideBar/SideBar';
import Base64ToPDFConverter from './modules/Converters/components/Base64ToPDF';
import UnixConverter from './modules/Converters/components/UnixConverter';
import ResHeader from './modules/Header/components/ResHeader';
import ResSidebar from './modules/SideBar/ResSidebar';
import RestroBilling from './modules/RestroBilling/components/RestroBilling';
import RestroTable from './modules/RestroSaga/components/RestroTable';
import RestroSettings from './modules/RestroSettings/components/RestroSettings';
import LoadingRes from './modules/Form/GenericFields/LoadingRes';
import InvoiceForm from './modules/InvoiceCreation/components/CreateInvoice/InvoiceForm';
import InvoiceReport from "./modules/InvoiceCreation/components/InvoiceReport/InvoiceReport";
import { getRedirect } from './modules/Utils/authUser';
import MasterDataDisplay from './modules/InvoiceCreation/components/MasterDataDisplay/MasterDataDisplay';
function mapStateToProps(state) {

    const { loginUserData, isLoading, errorMessage } = state.loginModuleReducer.GetLoginReducer;
    const { values: loginValues } = state.form.loginForm || {};

    return { loginUserData, isLoading, errorMessage, loginValues };
}

const mapDispatchToProps = {
    
};
class App extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         isLoggedIn: JSON.parse(localStorage.getItem("user"))?.role ? true :false,
    //         isLoading:false,
    //         isRedirect: false,
    //     redirectTo: getRedirect()
    //     };
    // }

    // componentDidMount() {
    //     // Check local storage for token or token expiration logic
    //     if(!JSON.parse(localStorage.getItem("user"))?.role) {
    //         localStorage.removeItem('token');
    //     }else if (!localStorage.getItem('token')) {
    //         localStorage.setItem("token", JSON.parse(localStorage.getItem("user")).token)
    //     }
    //     this.setState({isLoading: false})
    //     const token = localStorage.getItem('token');
    //     if (token) {
    //         // Here, you would typically validate the token's expiration
    //         this.setState({ isLoggedIn: true });
    //     } else {
    //         this.setState({ isLoggedIn: false });
    //     }
    // }
    // componentDidUpdate = (prevProps)=>{
    //     // const redirect = getRedirect();
    //     // if (!JSON.parse(localStorage.getItem("user"))?.role && !this.state.isRedirect) {
    //     //     this.setState({ isRedirect: true, redirectTo: redirect });
    //     // }
        
    // }

    render() {
        // const { isRedirect , redirectTo, isLoading} = this.state;
        // const isLoggedIn = JSON.parse(localStorage.getItem("user"))?.role ? true :false;
        return (
            
            <Router>
                 {/* {isRedirect && redirectTo && (<Navigate to={"/" + redirectTo} replace={true} render={true} />)} */}
                <div className="App">
                    {/* {isLoggedIn ? ( */}
                        <>
                            <ResHeader />
                            <ResSidebar>
                                <Routes>
                                    {/* <Route exact path="/" element={<UnixConverter />} /> */}
                                    <Route path="/" element={<Login />} />
                                    <Route path="/aboutus" element={<AboutUs />} />
                                    <Route path="/home" element={<Home />} />
                                    <Route path="/register" element={<Registration />} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/profile" element={<UserProfile />} />
                                    <Route path="/address" element={<UserAddress />} />
                                    <Route path="/base64topdf" element={<Base64ToPDFConverter />} />
                                    <Route path="/unixconverter" element={<UnixConverter />} />
                                    <Route path="/restrobilling" element={<RestroBilling />} />
                                    <Route path="/restrotable" element={<RestroTable />} />
                                    <Route path="/restrosetting" element={<RestroSettings />} />
                                    <Route path="/invoiceform" element={<InvoiceForm />} />
                                    <Route path="/invoicereport" element={<InvoiceReport />} />
                                    <Route path="/loadingpage" element={<LoadingRes />} />
                                    <Route path="/masterdata" element={<MasterDataDisplay/>} />
                                </Routes>
                            </ResSidebar>
                            {/* <Footer /> */}
                        </>
                    {/* ) : isLoading ? (
                        <Routes>
                        <Route path="/" element={<LoadingRes />} />
                    </Routes>
                    ) : (<>
                        <ResHeader />
                       
                        <Routes>
                            <Route path="/" element={<Login />} />
                        </Routes></>
                    )} */}
                </div>
            </Router>
        );
    }
}

export default  (App);
