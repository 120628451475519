export const getInvoiceDataSchema = () => `
query {
    getAllInvoices {
      invoiceId
      adminFirmName
      adminFirmAddress
      adminFirmState
      adminFirmStateCode
      adminGstNumber
      buyerFirmName
      buyerFirmAddress
      buyerGstNumber
      buyerFirmState
      buyerFirmStateCode
      productDetails {
        product_name
        quantity
        rate
        totalGross
        igst
        cgst
        sgst
        totalTax
        netAmount
      }
      subTotalGross
      subTotalTax
      subTotalNet
      subTotalNetInWords
      roundOffTotal
    }
  }
`