import React from 'react';

const IframePreview = ({pdfUrl,base64Content}) => {
    const srcUrl = `data:application/pdf;base64,${base64Content}`;
    return (
        <div className="container mt-5"  style={{  height: '1000px', }}>
       <iframe
            src={srcUrl}     
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="PDF Viewer"
        ></iframe>
      </div>
    );
};

export default IframePreview;