import {
    GET_FIRM_DATA_REQUEST,
    GET_FIRM_DATA_REQUEST_SUCCESS, GET_FIRM_DATA_REQUEST_FAILURE,
    SET_FIRM_DATA_REQUEST,
    SET_ADMIN_FIRM_DATA_REQUEST,
    SET_BUYER_FIRM_DATA_REQUEST,
    CREATE_FIRM_DATA_REQUEST,
    CREATE_FIRM_DATA_REQUEST_SUCCESS,
    CREATE_FIRM_DATA_REQUEST_FAILURE,
    UPDATE_FIRM_DATA_REQUEST,
    UPDATE_FIRM_DATA_REQUEST_SUCCESS,
    UPDATE_FIRM_DATA_REQUEST_FAILURE,
    DELETE_FIRM_DATA_REQUEST,
    DELETE_FIRM_DATA_REQUEST_FAILURE,
    DELETE_FIRM_DATA_REQUEST_SUCCESS,
    FILE_INSERT_FIRM_DATA_REQUEST,
    FILE_INSERT_FIRM_DATA_REQUEST_SUCCESS,
    FILE_INSERT_FIRM_DATA_REQUEST_FAILURE,
} from "../actions/firmData.action";
import { CREATE_INVOICE_REQUEST, CREATE_INVOICE_REQUEST_FAILURE, CREATE_INVOICE_REQUEST_SUCCESS, GET_INVOICE_DATA_REQUEST, GET_INVOICE_DATA_REQUEST_FAILURE, GET_INVOICE_DATA_REQUEST_SUCCESS, GET_PDF_BASE64_REQUEST, GET_PDF_BASE64_REQUEST_FAILURE, GET_PDF_BASE64_REQUEST_SUCCESS } from "../actions/invoiceData.action";
import { CREATE_SERVICE_DATA_REQUEST, CREATE_SERVICE_DATA_REQUEST_FAILURE, CREATE_SERVICE_DATA_REQUEST_SUCCESS, DELETE_SERVICE_DATA_REQUEST, DELETE_SERVICE_DATA_REQUEST_FAILURE, DELETE_SERVICE_DATA_REQUEST_SUCCESS, GET_SERVICE_DATA_REQUEST, GET_SERVICE_DATA_REQUEST_FAILURE, GET_SERVICE_DATA_REQUEST_SUCCESS, SET_ADDED_SERVICE_DATA_REQUEST, UPDATE_ADDED_SERVICE_DATA, UPDATE_SERVICE_DATA_REQUEST, UPDATE_SERVICE_DATA_REQUEST_FAILURE, UPDATE_SERVICE_DATA_REQUEST_SUCCESS } from "../actions/serviceData.action";


const initialState = {
    firmData: null,
    isLoading: false,
    errorMessage: null,
    serviceData: null,
    addedServiceData: null,
    addedAdminFirmData: null,
    addedBuyerFirmData: null,
    invoiceData: null,
    createInvoice: null,
    base64InvoicePDF: null,
    updateService: null,
    deletedService: null,
    updatedFirm: null,
    deletedFirm: null,
    uploadDataMessage: null
}

export const masterDataReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_FIRM_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                firmData: null,
            }
            break;
        case GET_FIRM_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                firmData: action.payload.firmMaster
            }
            break;
        case GET_FIRM_DATA_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                firmData: [],
                errorMessage: action.error.error
            }
            break;
        case GET_SERVICE_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                serviceData: null,
            }
            break;
        case GET_SERVICE_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                serviceData: action.payload.serviceData
            }
            break;
        case GET_SERVICE_DATA_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                serviceData: [],
                errorMessage: action.error.error
            }
            break;
        case SET_ADDED_SERVICE_DATA_REQUEST:
            return {
                ...state,
                isLoading: false,
                addedServiceData: state.addedServiceData ? [...state.addedServiceData, action.payload] : [action.payload]
            }
        case UPDATE_ADDED_SERVICE_DATA:
            const { id, updatedData } = action.payload;

            // Find the index of the item to update
            const updatedIndex = state.addedServiceData.findIndex(item => item.product_name === id);

            if (updatedIndex !== -1) {
                // Create a new array with the updated item
                const updatedServiceData = [
                    ...state.addedServiceData.slice(0, updatedIndex), // Items before the updated item
                    { ...state.addedServiceData[updatedIndex], ...updatedData }, // Updated item
                    ...state.addedServiceData.slice(updatedIndex + 1), // Items after the updated item
                ];

                return {
                    ...state,
                    addedServiceData: updatedServiceData,
                };
            }
            return state; // Return current state if item not found

        case SET_ADMIN_FIRM_DATA_REQUEST:
            return {
                ...state,
                isLoading: false,
                addedAdminFirmData: action.payload
            }
        case SET_BUYER_FIRM_DATA_REQUEST:
            return {
                ...state,
                isLoading: false,
                addedBuyerFirmData: action.payload
            }

        case GET_INVOICE_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                invoiceData: null
            }
        case GET_INVOICE_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                invoiceData: action.payload.getAllInvoices
            }
        case GET_INVOICE_DATA_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                invoiceData: action.error
            }
        case CREATE_INVOICE_REQUEST:
            return {
                ...state,
                isLoading: true,
                createInvoice: null
            }
        case CREATE_INVOICE_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                createInvoice: action.payload
            }
        case CREATE_INVOICE_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.error,
                createInvoice: null
            }
        case GET_PDF_BASE64_REQUEST:
            return {
                ...state,
                isLoading: true,
                base64InvoicePDF: null
            }
        case GET_PDF_BASE64_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                base64InvoicePDF: action.payload.getBase64Pdf
            }
        case GET_PDF_BASE64_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.error,
                createInvoice: null
            }
            case CREATE_FIRM_DATA_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                }
                break;
            case CREATE_FIRM_DATA_REQUEST_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    firmData: action.payload.createFirmMaster
                }
                break;
            case CREATE_FIRM_DATA_REQUEST_FAILURE:
                return {
                    ...state,
                    isLoading: false,
                    firmData: [],
                    errorMessage: action.error.error
                }
                break;
                case UPDATE_FIRM_DATA_REQUEST:
                    return {
                        ...state,
                        isLoading: true,
                        firmData:null
                    }
                    break;
                case UPDATE_FIRM_DATA_REQUEST_SUCCESS:
                    return {
                        ...state,
                        isLoading: false,
                        updatedFirm: action.payload.updateFirmMaster.message,
                        firmData: action.payload.updateFirmMaster.updatedFirmData
                    }
                    break;
                case UPDATE_FIRM_DATA_REQUEST_FAILURE:
                    return {
                        ...state,
                        isLoading: false,
                        firmData: [],
                        errorMessage: action.error.error
                    }
                    break;
                    case DELETE_FIRM_DATA_REQUEST:
                        return {
                            ...state,
                            isLoading: true,
                            firmData: null
                        }
                        break;
                    case DELETE_FIRM_DATA_REQUEST_SUCCESS:
                        return {
                            ...state,
                            isLoading: false,
                            deletedFirm: action.payload.deleteFirmMaster.message,
                            firmData: action.payload.deleteFirmMaster.updatedFirmData
                        }
                        break;
                    case DELETE_FIRM_DATA_REQUEST_FAILURE:
                        return {
                            ...state,
                            isLoading: false,
                            firmData: [],
                            errorMessage: action.error.error
                        }
                        break;
                    case CREATE_SERVICE_DATA_REQUEST:
                        return {
                            ...state,
                            isLoading: true,
                        }
                        break;
                    case CREATE_SERVICE_DATA_REQUEST_SUCCESS:
                        return {
                            ...state,
                            isLoading: false,
                            serviceData: action.payload.createServiceData
                        }
                        break;
                    case CREATE_SERVICE_DATA_REQUEST_FAILURE:
                        return {
                            ...state,
                            isLoading: false,
                            serviceData: [],
                            errorMessage: action.error.error
                        }
                        break;
                        case UPDATE_SERVICE_DATA_REQUEST:
                            return {
                                ...state,
                                isLoading: true,
                                updateService: null
                            }
                            break;
                        case UPDATE_SERVICE_DATA_REQUEST_SUCCESS:
                            return {
                                ...state,
                                isLoading: false,
                                updateService: action.payload.updateServiceData.message,
                                serviceData: action.payload.updateServiceData.updatedServiceData
                            }
                            break;
                        case UPDATE_SERVICE_DATA_REQUEST_FAILURE:
                            return {
                                ...state,
                                isLoading: false,
                                updateService: null,
                                errorMessage: action.error.error
                            }
                            break;
                            case DELETE_SERVICE_DATA_REQUEST:
                                return {
                                    ...state,
                                    isLoading: true,
                                    deletedService: null
                                }
                                break;
                            case DELETE_SERVICE_DATA_REQUEST_SUCCESS:
                                return {
                                    ...state,
                                    isLoading: false,
                                    deletedService: action.payload.deleteServiceData.message,
                                serviceData: action.payload.deleteServiceData.updatedServiceData
                                }
                                break;
                            case DELETE_SERVICE_DATA_REQUEST_FAILURE:
                                return {
                                    ...state,
                                    isLoading: false,
                                    serviceData: [],
                                    errorMessage: action.error.error
                                }
                                break;
                                case FILE_INSERT_FIRM_DATA_REQUEST:
                                    return {
                                        ...state,
                                        isLoading: true,
                                        firmData: null
                                    }
                                    break;
                                case FILE_INSERT_FIRM_DATA_REQUEST_SUCCESS:
                                    return {
                                        ...state,
                                        isLoading: false,
                                        uploadDataMessage: action.payload.uploadFirmMasterExcel.message,
                                    firmData: action.payload.uploadFirmMasterExcel.updatedFirmData
                                    }
                                    break;
                                case FILE_INSERT_FIRM_DATA_REQUEST_FAILURE:
                                    return {
                                        ...state,
                                        isLoading: false,
                                        firmData: [],
                                        errorMessage: action.error.error
                                    }
                                    break;
        default:
            return {
                ...state
            }
            break;
    }
}