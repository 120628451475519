export const GET_INVOICE_DATA_REQUEST ='GET_INVOICE_DATA_REQUEST';
export const GET_INVOICE_DATA_REQUEST_SUCCESS ='GET_INVOICE_DATA_REQUEST_SUCCESS';
export const GET_INVOICE_DATA_REQUEST_FAILURE ='GET_INVOICE_DATA_REQUEST_FAILURE';
export const CREATE_INVOICE_REQUEST ='CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_REQUEST_SUCCESS ='CREATE_INVOICE_REQUEST_SUCCESS';
export const CREATE_INVOICE_REQUEST_FAILURE ='CREATE_INVOICE_REQUEST_FAILURE';
export const GET_PDF_BASE64_REQUEST ='GET_PDF_BASE64_REQUEST';
export const GET_PDF_BASE64_REQUEST_SUCCESS ='GET_PDF_BASE64_REQUEST_SUCCESS';
export const GET_PDF_BASE64_REQUEST_FAILURE ='GET_PDF_BASE64_REQUEST_FAILURE';

export const getInvoiceDataRequest = (payload) => ({
    type: GET_INVOICE_DATA_REQUEST,payload
}); 

export const getInvoiceDataRequestSuccess = (payload) => ({
    type: GET_INVOICE_DATA_REQUEST_SUCCESS, payload
}); 

export const getInvoiceDataRequestFailure = (error) => ({
    type: GET_INVOICE_DATA_REQUEST_FAILURE , error
}); 

export const createInvoiceRequest = (payload) => ({
    type: CREATE_INVOICE_REQUEST,payload
}); 

export const createInvoiceRequestSuccess = (payload) => ({
    type: CREATE_INVOICE_REQUEST_SUCCESS, payload
}); 

export const createInvoiceRequestFailure = (error) => ({
    type: CREATE_INVOICE_REQUEST_FAILURE , error
}); 

export const getPDFBase64Request = (payload) => ({
    
    type: GET_PDF_BASE64_REQUEST,payload
}); 

export const getPDFBase64RequestSuccess = (payload) => ({
    type: GET_PDF_BASE64_REQUEST_SUCCESS, payload
}); 

export const getPDFBase64RequestFailure = (error) => ({
    type: GET_PDF_BASE64_REQUEST_FAILURE , error
});