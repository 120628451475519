import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormattedDate } from '../../../Utils/dateTimeFormate';


function mapStateToProps(state) {

  const { addedServiceData, addedAdminFirmData, addedBuyerFirmData, serviceData } = state.masterDataModuleReducer.masterDataReducer;

  return { addedServiceData, addedAdminFirmData, addedBuyerFirmData, serviceData };
}

const mapDispatchToProps = {

};

class InvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { addedAdminFirmData, addedBuyerFirmData, addedServiceData,invoiceData } = this.props;
    const {
      adminFirmAddress,
      adminFirmName,
      adminFirmState,
      adminFirmStateCode,
      adminGstNumber,
      bankAccountNumber,
      bankBranchAddress,
      bankBranchName,
      bankIFSCCode,
      buyerFirmAddress,
      buyerFirmName,
      buyerFirmState,
      buyerFirmStateCode,
      buyerGstNumber,
      invoiceId,
      productDetails,
      roundOffTotal,
      subTotalGross,
      subTotalTax,
      subTotalNet,
      subTotalNetInWords
    } = invoiceData;
    return (
      <div>
        <table border="0" cellpadding="0" cellspacing="0" id="sheet0" class="sheet0 gridlines">
          <col class="col0" />
          <col class="col1" />
          <col class="col2" />
          <col class="col3" />
          <col class="col4" />
          <col class="col5" />
          <col class="col6" />
          <col class="col7" />
          <col class="col8" />
          <col class="col9" />
          <col class="col10" />
          <col class="col11" />
          <col class="col12" />
          <col class="col13" />
          <tbody>
            <tr class="row0">
              <td class="column0 style67 s style69" colspan="11">Tax Invoice</td>



            </tr>
            <tr class="row1">
              <td class="column0 style51 null style51" colspan="2" rowspan="5">
                <div style={{position:"relative",top:"-37px"}}>  <img style={{ width: "195px", height: "94px" }} src="asset/logo/logo.jpg" border="0" />
                </div>
              </td>
              <td class="column2 style52 s style52" colspan="6">{adminFirmName}</td>
              <td class="column8 style21 s">INV No.</td>
              <td class="column9 style64 s style64" colspan="2">{invoiceId}</td>



            </tr>
            <tr class="row2">
              <td class="column2 style53 s style53" colspan="6" rowspan="2">{adminFirmAddress}</td>
              <td class="column8 style5 s">INV Date</td>
              <td class="column9 style65 n style65" colspan="2">{getFormattedDate()}</td>



            </tr>
            <tr class="row3">
              <td class="column8 style7 s">Mode/Terms of Payment</td>
              <td class="column9 style64 null style64" colspan="2"></td>



            </tr>
            <tr class="row4">
              <td class="column2 style53 s style53" colspan="6" rowspan="2">State Code -{adminFirmStateCode}  <br />GSTN No. {adminGstNumber} </td>
              <td class="column8 style5 s">Supplier's Ref.</td>
              <td class="column9 style66 null style66" colspan="2"></td>



            </tr>
            <tr class="row5">
              <td class="column8 style5 s">Other Reference(s) </td>
              <td class="column9 style64 s style64" colspan="2">Mr. Abhilash Reddy</td>



            </tr>
            <tr class="row6">
              <td class="column0 style54 s style54" colspan="8">            <span style={{ fontWeight: "bold", color: "#000000", fontFamily: 'Calibri', fontSize: "10pt" }}>Buyer <br />{buyerFirmName}</span>
                <span style={{ color: "#000000", fontFamily: 'Calibri', fontSize: "10pt" }}>
                  <br />{buyerFirmAddress} <br /> GSTIN/UIN : {buyerGstNumber} <br /> State Name : {buyerFirmState}, Code {buyerFirmStateCode} </span></td>
              <td class="column8 style70 s style70" colspan="3">Terms of Delivery</td>



            </tr>
            <tr class="row7">
              <td class="column0 style38 s style38" colspan="2">Purchase order Date</td>
              <td class="column2 style47 null style47" colspan="6"></td>
              <td class="column8 style39 s style39" rowspan="2">Shipped To</td>
              <td class="column9 style48 s style48" colspan="2" rowspan="2">Koppal</td>



            </tr>
            <tr class="row8">
              <td class="column0 style38 s style38" colspan="2">Purchase order No.</td>
              <td class="column2 style49 null style49" colspan="6"></td>



            </tr>
            <tr class="row9">
              <td class="column0 style10 s">Sl No.</td>
              <td class="column1 style10 s">Description of Services</td>
              <td class="column2 style10 s">HSN/SAC</td>
              <td class="column3 style10 s">Quantity</td>
              <td class="column4 style11 s">Rate per count (INR)</td>
              <td class="column5 style10 s">IGST %</td>
              <td class="column6 style10 s">CGST %</td>
              <td class="column7 style10 s">SGST %</td>
              <td class="column8 style9 s">&nbsp;Net Amount (Rs.)</td>
              <td class="column9 style8 s">IGST Amount (Rs)</td>
              <td class="column10 style8 s">Gross Amount (Rs)</td>
            </tr>
            {productDetails?.map((service, index) => (
              <tr class="row10" key={index}>
                <td class="column0 style12 n">{index + 1}</td>
                <td class="column1 style13 s">{service.product_name}</td>
                <td class="column2 style12 n">{service.hsn}</td>
                <td class="column3 style12 n">{service.quantity}</td>
                <td class="column4 style14 n">{service.rate}</td>
                <td class="column5 style15 n">{service.igst + "%"}</td>
                <td class="column6 style15 n">{service.cgst + "%"}</td>
                <td class="column7 style15 n">{service.sgst + "%"}</td>
                <td class="column8 style16 f">{service.netAmount}</td>
                <td class="column9 style16 f">{service.totalTax}</td>
                <td class="column10 style16 f">{service.totalGross}</td>
              </tr>
            ))}
            <tr class="row11">
              <td class="column0 style43 s style43" colspan="8">Sub Total</td>
              <td class="column8 style17 f">{subTotalNet}</td>
              <td class="column9 style17 f">{subTotalTax}</td>
              <td class="column10 style17 f">{subTotalGross}</td>
              <td class="column11 style19 null"></td>


            </tr>
            <tr class="row12">
              <td class="column0 style39 s style39" colspan="8">Round off (±50)</td>
              <td class="column8 style20 n">0.00</td>
              <td class="column9 style20 n">0.00</td>
              <td class="column10 style20 n">0.00</td>



            </tr>
            <tr class="row13">
              <td class="column0 style44 s style44" colspan="8">Total </td>
              <td class="column8 style22 f">{subTotalNet}</td>
              <td class="column9 style22 f">{subTotalTax}</td>
              <td class="column10 style22 f">{subTotalGross}</td>



            </tr>
            <tr class="row14">
              <td class="column0 style23 s">Invoice Value (in words)   </td>
              <td class="column1 style23 null"></td>
              <td class="column2 style45 s style45" colspan="9">{subTotalNetInWords}</td>



            </tr>
            <tr class="row15">
              <td class="column0 style45 s style45" colspan="11">HSN/SAC Code</td>



            </tr>
            <tr class="row16">
              <td class="column0 style39 s style39" rowspan="2">HSN/SAC </td>
              <td class="column1 style46 s style46" colspan="3" rowspan="2">GST Rate</td>
              <td class="column4 style46 s style46" rowspan="2">Taxable Value</td>
              <td class="column5 style39 s style39" colspan="2">CGST</td>
              <td class="column7 style39 s style39" colspan="2">SGST</td>
              <td class="column9 style39 s style39" colspan="2">IGST</td>



            </tr>
            <tr class="row17">
              <td class="column5 style8 s">Rate</td>
              <td class="column6 style8 s">Amount</td>
              <td class="column7 style8 s">Rate</td>
              <td class="column8 style8 s">Amount</td>
              <td class="column9 style8 s">Rate</td>
              <td class="column10 style8 s">Amount</td>



            </tr>
            {productDetails?.map((service, index) => (
            <tr class="row18" key={index}>
              <td class="column0 style24 f">{service.hsn}</td>
              <td class="column1 style71 f style71" colspan="3">{parseInt(service.igst + service.cgst + service.sgst)  + "%"}</td>
              <td class="column4 style26 f">{service.totalTax}</td>
              <td class="column5 style27 n">{service.cgst ? service.cgst  + "%" : 0}</td>
              <td class="column6 style27 n">{service.netAmount * (service.cgst / 100)}</td>
              <td class="column7 style27 n">{service.sgst ? service.sgst  + "%" : 0}</td>
              <td class="column8 style27 n">{service.netAmount * (service.sgst / 100)}</td>
              <td class="column9 style28 f">{service.igst ? service.igst  + "%" : 0}</td>
              <td class="column10 style29 f">{service.netAmount * (service.igst / 100)}</td>
            </tr>))}
            <tr class="row19">
              <td class="column0 style40 s style40" colspan="4">Total</td>
              
              <td class="column4 style30 null"></td>
              <td class="column5 style30 null"></td>
              <td class="column6 style30 null">{buyerFirmState === adminFirmState ? parseFloat(subTotalTax/2) : 0}</td>
              <td class="column7 style32 null"></td>
              <td class="column8 style17 f">{buyerFirmState === adminFirmState  ? parseFloat(subTotalTax/2) : 0}</td>
              <td class="column9 style72 null"></td>
              <td class="column10 style17 f">{buyerFirmState !== adminFirmState  ? parseFloat(subTotalTax) : 0}</td>



            </tr>
            <tr class="row20">
              <td class="column0 style41 s style41" colspan="7">Company's Bank Details </td>
              <td class="column7 style42 s style42" colspan="4">Company's PAN : AAUCA6407E</td>



            </tr>
            <tr class="row21">
              <td class="column0 style42 s style42" colspan="7">Bank Name : ICICI Bank</td>
              <td class="column7 style35 null style35" colspan="4"></td>



            </tr>
            <tr class="row22">
              <td class="column0 style35 s style35" colspan="7">A/c No. : 777705090121</td>
              <td class="column7 style36 null style36" colspan="4"></td>



            </tr>
            <tr class="row23">
              <td class="column0 style35 s style35" colspan="7">Branch: Plot No. Z-14, Heera Complex Zone-1 MP Nagar Bhopal MP - 462011</td>
              <td class="column7 style37 s style37" colspan="4">For Agribook Tech Solution and Allied Services Pvt. Ltd.                                                                                                                                               </td>



            </tr>
            <tr class="row24">
              <td class="column0 style35 s style35" colspan="7">IFSC Code: ICIC0001193</td>
              <td class="column7 style36 s style36" colspan="4" rowspan="5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Authorized Signatory</td>



            </tr>
            <tr class="row25">
              <td class="column0 style38 s style38" colspan="7" rowspan="4">Declaration:- We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.</td>



            </tr>
            <tr class="row26">



            </tr>
            <tr class="row27">



            </tr>
            <tr class="row28">



            </tr>
            <tr class="row29">
              <td class="column0 style34 s style34" colspan="11">This is a Computer-Generated Invoice</td>



            </tr>
          </tbody>
        </table>

      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(InvoicePreview);