import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

const client = new ApolloClient({
    uri: 'http://localhost:8080/graphql', // Replace with your GraphQL endpoint
    cache: new InMemoryCache(),
});

const Query =(schema, token) =>{
    return client.query({
        query: gql`${schema}`
    })
}

const Mutation =(schema, token) =>{
    return client.mutate({
        mutation: gql`${schema}`
    })
}

export { Query, Mutation}