import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInvoiceDataRequest } from '../../redux/actions/invoiceData.action';
import InvoiceTable from './InvoiceTable';
import { Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { FaPen } from 'react-icons/fa';
import LoadingIndicator from '../../../Form/LoadingIndicator/LoadingIndicator';

function mapStateToProps(state) {
   
    const { invoiceData , isLoading} = state.masterDataModuleReducer.masterDataReducer;

    return { invoiceData , isLoading};

}

const mapDispatchToProps = {
   getInvoiceDataRequest
}

class InvoiceReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRedirect: false, 
            redirectTo: ''
        }
    }

    componentDidMount= () => {
        this.props.getInvoiceDataRequest();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.invoiceData !== this.props.invoiceData) {
        }
        
    }

    createInvoice = () =>{
        this.setState({isRedirect: true, redirectTo: 'invoiceform'})
    }

    render() {
        const { invoiceData } = this.props;
        const {isRedirect, redirectTo}= this.state;
        return (
            <div>
                {this.props.isLoading && <LoadingIndicator isOverlay={true} pageLoader={true} />}
                {isRedirect && redirectTo && (<Navigate to={"/" + redirectTo} replace={true} render={true} />)}
                <div className="d-flex gap-2 justify-content-end py-2">
                 <Button variant="primary" size="lg" onClick={this.createInvoice}>
                 <FaPen /> {" "}Create Invoice
                  </Button>
                </div>
               {invoiceData && <InvoiceTable invoiceData={invoiceData} />}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,mapDispatchToProps
)(InvoiceReport);