
export const getAuthToken = () => {
    const userToken = localStorage.getItem("token")
    return userToken ? { "x-access-token" : userToken ,  'Content-Type': 'application/json'  } : { 'Content-Type': 'application/json' }
}

export const getRedirect = () => {
    const role= JSON.parse(localStorage.getItem("user"))?.role;
            if (role === "invoiceSaga") {
                return "invoicereport"
            }
            if (role === "restroSaga") {
                return "restrobilling"
            }else {
                return "login"
            }
}