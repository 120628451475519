import {
    GET_LOGIN_REQUEST,
    GET_LOGIN_REQUEST_SUCCESS, GET_LOGIN_REQUEST_FAILURE
} from "../actions/getLoginData.action";


const initialState = {
    loginUserData: [],
    isLoading: false,
    errorMessage: null
}

export const GetLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
                loginUserData: [],
            }
            break;
        case GET_LOGIN_REQUEST_SUCCESS:
            console.log(action.payload.login);
            return {
                ...state,
                isLoading: false,
                loginUserData: action.payload.login
            }
            break;
        case GET_LOGIN_REQUEST_FAILURE:
            console.log(action.error);
            return {
                ...state,
                isLoading: false,
                loginUserData: [],
                errorMessage: action.error.error
            }
            break;

        default:
            return {
                ...state
            }
            break;
    }
}