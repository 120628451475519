import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResSidebar from '../../SideBar/ResSidebar';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class ResHeader extends Component {
    render() {
        return (
            <header className='bg-light fixed-top' style={{ boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)" }} >
            <nav className="navbar container navbar-expand-lg navbar-light ">
              <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
               <h3>{"PlatformOne"}</h3>
              </a>
              </nav>
              </header>
        );
    }
}

export default connect(
    mapStateToProps,
)(ResHeader);