import { call, put } from "redux-saga/effects";


function* sagaHandle(fn,schema ,onSuccess, onFailure, withoutToken) {
    try {
       
        const {data} = yield call(fn, schema);

        if (data && onSuccess) {
            yield put(onSuccess(data));
            return data;
        }
    } catch (error) {
        if (onFailure) {
            yield put(onFailure({
                error: error,
                errorCodes: "",
            }));
        }
    
    }
}

export {
    sagaHandle  
}